import Landing from './Landing/Landing';
import GetStarted from "./GetStarted/GetStarted";
import CompareImages from "./CompareImages/CompareImages";

export default function Home() {
    return (
        <>
            <Landing />
            <GetStarted />
            <CompareImages />
        </>
    )
}